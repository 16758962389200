import { useState, useEffect, Fragment, useLayoutEffect } from "react";
import MetaComponent from "../components/Meta";
import { BRAND_IMAGE, BRAND_TAGLINE } from "../lib";
import {
  getFirestore,
  collection,
  setDoc,
  doc,
  onSnapshot,
  where,
  query,
} from "firebase/firestore";
import app from "../firebase.config";
import toast from "react-hot-toast";
import { useRouter } from "next/router";

const db = getFirestore(app);

const Home = () => {
  const [items, setItems] = useState<any>([]);
  const router = useRouter();
  const [id, setId] = useState<string>("");
  const [user, setUser] = useState<any>([]);

  const { from } = router.query;
  const { to } = router.query;

  const fetchData = async () => {
    const q = query(
      collection(db, "chat"),
      where("participants", "in", [`${from}-${to}`, `${to}-${from}`])
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data: any = [];
      querySnapshot.forEach((doc) => {
        setId(doc.id);
        if (doc.exists()) {
          data.push(doc.data());
        }
      });
      setItems(data);
    });
  };

  const fetchUser = async () => {
    const q = query(collection(db, "users"), where("email", "==", to));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data: any = [];
      querySnapshot.forEach((doc) => {
        setId(doc.id);
        if (doc.exists()) {
          data.push(doc.data());
        }
      });
      setUser(data);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!e.target.message.value) return;

    const data = {
      participants: `${from}-${to}`,
      messages: [
        ...(items[0]?.messages ?? []),
        {
          from: from,
          message: e.target.message.value,
          createdAt: new Date(),
        },
      ],
      read: false,
    };
    try {
      const messagesRef = doc(db, "chat", `${from}-${to}`);
      await setDoc(messagesRef, data, { merge: false });
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    } finally {
      e.target.reset();
    }
  };

  const handleSuggestionClick = async (suggestion: string) => {
    const data = {
      participants: `${from}-${to}`,
      messages: [
        ...(items[0]?.messages ?? []),
        {
          from: from,
          message: suggestion,
          createdAt: new Date(),
        },
      ],
      read: false,
    };
    try {
      const messagesRef = doc(db, "chat", `${from}-${to}`);
      await setDoc(messagesRef, data, { merge: false });
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  useEffect(() => {
    if (from && to) {
      fetchData();
      fetchUser();
    }
  }, [from, to]);

  useLayoutEffect(() => {
    const chat = document.getElementById("chat");
    if (chat) {
      chat.scrollTop = chat.scrollHeight;
    }
  }, [items]);

  return (
    <>
      <MetaComponent title={BRAND_TAGLINE} />

      <div className="container mx-auto">
        <div className="min-w-full border rounded ">
          <div className="lg:col-span-2 lg:block">
            <div className="w-full">
              {/* <div className="md:invisible lg:invisible">
              <PlusComponent/>
              </div> */}

              <div className="relative flex items-center p-3 border-b border-gray-300">
                <img
                  className="object-cover w-10 h-10 rounded-full"
                  src={user[0]?.photoDownloadUrl ?? BRAND_IMAGE}
                  alt="logo"
                />
                <span className="block ml-2 font-bold text-gray-600">
                  {user[0]?.name.split(" ")[0]}
                </span>
              </div>
              <div
                className="relative w-full p-6 overflow-y-auto h-[40rem]"
                id="chat"
              >
                <ul className="space-y-2">
                  {items[0]?.messages.length > 0 &&
                    items[0]?.messages.map((item: any, i: number) => (
                      <Fragment key={i}>
                        {item?.from === to ? (
                          <li className="flex justify-start">
                            <div className="relative max-w-xl px-4 py-2 text-gray-700 rounded shadow">
                              <span className="block break-all">
                                {item.message}
                              </span>
                              <span className="block mx-2 text-xs text-gray-500">
                                {item.createdAt?.toDate().toLocaleString()}
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li className="flex justify-end">
                            <div className="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
                              <span className="block break-all">
                                {item.message}
                              </span>

                              <span className="block mx-2 text-xs text-gray-500">
                                {item.createdAt?.toDate().toLocaleString()}
                              </span>
                            </div>
                          </li>
                        )}
                      </Fragment>
                    ))}
                </ul>
              </div>

              {items && items?.length < 1 && (
                <div className="justify-center my-8 select-none flex">
                  <button
                    onClick={() =>
                      handleSuggestionClick(`Hi ${user[0]?.name.split(" ")[0]}`)
                    }
                    className="py-2 px-4 shadow-md no-underline rounded-full bg-blue font-sans font-semibold text-sm hover:text-blue-500 hover:bg-blue-light focus:outline-none active:shadow-none mr-2"
                  >
                    Hi {user[0]?.name.split(" ")[0]}
                  </button>
                  <button
                    onClick={() =>
                      handleSuggestionClick(`Hi ${
                        user[0]?.name.split(" ")[0]
                      }, I saw your profile and I
                    am interested to connect with you.`)
                    }
                    className="py-2 px-4 shadow-md no-underline rounded-full bg-orange font-sans font-semibold text-sm hover:text-blue-500 hover:bg-orange-light focus:outline-none active:shadow-none mr-2"
                  >
                    Hi {user[0]?.name.split(" ")[0]}, I saw your profile and I
                    am interested to connect with you.
                  </button>
                </div>
              )}

              <div className="flex items-center justify-between w-full p-3 border-t border-gray-300">
                <form
                  onSubmit={handleSubmit}
                  className="flex items-center w-full"
                >
                  <textarea
                    name="message"
                    placeholder={`Start a conversation with ${
                      user[0]?.name.split(" ")[0]
                    }  by typing a message`}
                    className="w-full h-10 px-4 py-2 text-gray-700 bg-gray-100 rounded shadow"
                  />
                  <button
                    type="submit"
                    className="flex items-center justify-center w-10 h-10 ml-2 text-gray-100 bg-blue-500 rounded shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                      />
                    </svg>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

// options for pop up chats
// add time stamp
// encrypt data in database
// transalate to french
